<template>
	<div class="flex flex-col gap-4">
		<boxLoader v-if="loading || fetchingAllChargeTypes" />
		<template v-else>
			<div class="flex items-center gap-2">
				<router-link :to="{name: 'ConfigureCharges'}" class="">Configure<img src="@/assets/img/caret-right.svg" alt="" /></router-link>
				<p v-if="currentChargeType" class="text-xs text-[#000005] font-medium">
					{{ currentChargeType.name }}
				</p>
			</div>
			<div class="bg-white border rounded-lg p-[16px] flex flex-col gap-[16px]">
				<div class="flex flex-col gap-[16px] lg:flex-row lg:items-center lg:justify-between">
					<div class="flex flex-col gap-2">
						<p class="text-lg text-[#000005] font-medium">{{currentChargeType.short_name}}-{{currentChargeType.name}}</p>
						<p class="text-sm text-[#737876]">
							{{ currentChargeType.description }}
						</p>
					</div>
					<div class="flex items-stretch gap-2 shrink-0">
						<button type="button" class="border-none outline-none font-medium bg-transparent text-[#0DAC5C] underline" @click="showAddTypeModal = true">
							Additional charge
						</button>
						<button type="button" @click="initEditConfigure(config)" class="px-[16px] py-2 bg-transparent rounded w-fit border outline-none text-sm text-[#101211] font-medium">
							Modify
						</button>
						<button @click="deleteConfiguredCharge(config.id)" type="button" class="px-[16px] py-2 bg-transparent rounded w-fit border border-danger outline-none">
							<img src="@/assets/img/delete_bucket.svg" alt="">
						</button>
					</div>
				</div>
				<div class="border"></div>
				<div class="flex items-center flex-wrap gap-6 md:gap-[16px] justify-between">
					<div class="flex flex-col gap-1 text-sm text-[#101211] font-medium">
						<p>{{ config.country_currently_active_in.name }}</p>
						<div class="flex flex-wrap gap-x-2 gap-y-0 max-w-[150px]">
							<p class="text-[#737876] text-xs" v-for="n,i in config.cities_currently_active_in" :key="i">
								{{n.city_name}}
							</p>
						</div>
					</div>
					<p class="text-sm text-[#0DAC5C] font-medium">{{config.is_compulsory ? 'Compulsory' : 'Not compulsory'}}</p>
					<div class="flex flex-col gap-1 text-sm ">
						<p class="text-[#8D918F]">Charge</p>
						<p class="text-[#6E717C] font-medium">
							<template v-if="config.charge_type === 'flat'">
								N{{ config.charge_value }} flat
							</template>
							<template>
								{{config.charge_value}} Percent (%)
							</template>
						</p>
					</div>
					<div class="flex flex-col gap-1 text-sm">
						<p class="text-[#8D918F]">Configured by</p>
						<p class="text-[#2C8EED] font-medium">
							{{ config.updated_by_data.fname || '' }} {{ config.updated_by_data.lname || '' }}
						</p>
					</div>
					<div class="flex flex-col gap-1 text-xs text-[#313533] font-medium">
						<p>{{ moment(config.created_at).format('LT') }}</p>
						<p>{{ moment(config.created_at).format('LL') }}</p>
					</div>
					<p class="text-xs py-1 px-2 rounded-full font-medium w-fit"
						:class="config.is_active ? 'st_active' : 'st_inactive'"
					>
						{{ config.is_active ? 'active' : 'inactive' }}
					</p>
				</div>
			</div>
		</template>

		<div class="flex flex-col bg-white border rounded-lg">
			<div class="flex flex-col gap-y-2 gap-x-[16px] md:flex-row md:items-center md:justify-between p-[16px] border-bottom">
				<!-- <v-datepicker  v-model="date" placeholder="Filter by date"></v-datepicker> -->
				<date-picker :range="true" class="w-full md:max-w-[220px]" v-model="date" valueType="format" placeholder="Filter by date"></date-picker>
				<div class="flex gap-[16px] items-center ">
					<button @click="showMarkChargesModal = true" class="bg-black text-white text-xs font-medium px-4 py-3 w-fit border-none rounded">
						Mark as remitted
					</button>
					<button class="bg-transparent border-none outline-none w-fit px-3 py-2 flex items-center gap-2">
						<img src="@/assets/img/downloadIcon.svg" alt="">
						Download Report
					</button>
				</div>
			</div>
			<div class="flex flex-col gap-y-2 gap-x-[16px] md:flex-row md:items-center md:justify-between py-3 px-[16px] border-bottom">
				<p class="text-sm font-medium text-[#6E717C]">Total VAT: <span class="text-[#000005]">₦754,763</span></p>
				<div class="overflow-hidden flex items-stretch rounded border bg-[#F4F5F4] ">
					<button v-for="n,i in ['All', 'Remitted', 'Unremitted']" :key="i"
						class="py-2 px-4 border-none" @click="status = n"
						:class="[status == n ? 'text-[#101211] !bg-white' : 'text-[#ACAFAE] bg-transparent', i === 1 ? 'border-right border-left' : '']"
					>
					{{ n }}
					</button>
				</div>
			</div>

			<div>
				<b-table show-empty :busy="loading_history" responsive striped :items="chargeHistory" :fields="fields" class='cursor-pointer'>
					<template #table-busy>
						<div class="text-center text-secondary my-2">
							<strong>Loading...</strong>
						</div>
					</template>
					<template #empty>
						<h4 class="text-center py-5 text-sm">No charge has been collected for this period.</h4>
					</template>
					<template #cell(sn)="data">
						{{ (currentPage - 1) * perPage + data.index + 1 }}
					</template>
					<template #cell(route)="data">
						<p class="text-sm text-[#101211] whitespace-nowrap">SG323</p>
					</template>
					<template #cell(users)="data">
						<div class="flex flex-col gap-1 text-sm text-[#101211]">
							<p class="font-medium">Abuabkri Sherif</p>
							<p class="text-[#737876]">saykeed@gmail.com</p>
						</div>
					</template>
					<template #cell(t_amount)="data">
						<p class="text-sm text-[#313533]">
							₦1750
						</p>
					</template>
					<template #cell(c_amount)="data">
						<p class="text-sm text-[#313533]">
							₦1750
						</p>
					</template>
					<template #cell(date)="data">
						<p class="text-sm text-[#313533] font-medium">21-10-2023</p>
					</template>
				</b-table>
				<b-pagination v-if="chargeHistory.length" v-model="currentPage" :total-rows="chargeHistoryMeta.total" :per-page="perPage"></b-pagination>
			</div>
		</div>
		
		<BS_modal :show='showMarkChargesModal' title='Mark charges as remitted' @close='closeMarkChargesModal' modalId='markCharge'>
			<div class="flex flex-col gap-4">
				<div class="flex flex-col gap-2">
					<label class="text-xs text-[#6E717C] font-medium">Date range</label>
					<date-picker :range="true" v-model="remit_date" valueType="format" placeholder="Filter by date"></date-picker>
				</div>
				<div class="flex items-center justify-between text-sm font-medium py-3">
					<p class="text-[#6E717C]">Total VAT</p>
					<p class="text-[#313533]">₦0.00</p>
				</div>
				<div class="flex items-start gap-2">
					<img src="@/assets/img/icons/wallet/info.svg" class="shrink-0 w-[15px] mt-1" alt="">
					<p class="text-sm text-[#575A65]">Charges within the specified date period should only be marked as 'remitted' if the additional charges have been submitted to the official body.</p>
				</div>

				<button :disabled="!remit_date[0] && !remit_date[1]" @click="showRemitChargeModal = true"
					class="w-full p-[16px] text-white bg-black rounded border-none outline-none text-sm mt-2 disabled:cursor-not-allowed disabled:bg-[#E0E6ED]"
				>
					Mark as remitted
				</button>
			</div>
		</BS_modal>

		<BS_modal :show='showRemitChargeModal' title='Are you absolutely sure?' @close='closeRemitChargesModal' modalId='remitCharge'>
			<form class="flex flex-col gap-4" @submit.prevent="markAsRemitted()">
				<p>Are you sure you want to <strong>Remit charges</strong> for the selected month?</p>
				<div class="p-3 rounded bg-[#EBEBFF] flex items-center gap-3">
					<img src="@/assets/img/caution.svg" alt="">
					<span class="text-sm font-medium text-[#2323F0]">It’s important you read the message below!</span>
				</div>
				<p class="text-xs m-0">The selected period would be permanently marked as remitted after confirming this action.</p>
				<div class="" autocomplete="off">
					<div class="form-group mb-0 relative" autocomplete="off">
						<label class="!mb-2">Please type <strong>YOUR PASSWORD</strong> to confirm this action.</label>
						<input required v-model="password" :type="showPassword ?'text':'password'" class="form-control" autocomplete='off' />
						<span @click="showPassword = !showPassword" class="fe absolute right-4 top-[34px] cursor-pointer" :class="[showPassword ? 'fe-eye' : 'fe-eye-off']"></span>
					</div>
				</div>

				<div class="mt-[30px] flex flex-col gap-2">
					<button type="submit" class='btn bg-dark text-white w-full disabled:cursor-not-allowed' :disabled='!password.length || remitting'>
						{{remitting ? 'loading...' : 'Proceed' }}
					</button>
				</div>
			</form>
		</BS_modal>
	</div>
</template>

<script setup>
import {ref, computed, watch} from 'vue'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import { useFetchConfiguredCharges, useCreateConfigureCharge, useDeleteConfiguredCharge, useDetails, useRemitted } from '../../composables/charges/configure/index'
import { useFetchChargeTypes, useCreateChargeType } from '../../composables/charges/chargeTypes/index'
import { useRoute } from 'vue-router/composables'
import boxLoader from '@/components/core/boxLoader.vue'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'

const { fetchSingleConfiguredCharges, loading, singleConfiguredCharge: config } = useFetchConfiguredCharges()
const { fetchAllChargeTypesWithoutLimit, loading: fetchingAllChargeTypes, allChargeTypes } = useFetchChargeTypes()
const { deleteConfiguredCharge, loading:deleting } = useDeleteConfiguredCharge()
const { showAddTypeModal } = useCreateChargeType()
const { initEditConfigure } = useCreateConfigureCharge()
const { loading:loading_history, chargeHistory, chargeHistoryMeta, fetchHistory, currentPage, perPage, status, date } = useDetails()
const { password, showPassword, loading:remitting, date:remit_date, markAsRemitted, showMarkChargesModal, showRemitChargeModal, closeMarkChargesModal, closeRemitChargesModal } = useRemitted()
const route = useRoute()

const fields = [
	{ key: 'sn', label: 'S/N' },
	{ key: 'users', label: 'Users' },
	{ key: 'route', label: 'Route' },
	{ key: 't_amount', label: 'Total amount' },
	{ key: 'c_amount', label: 'Charge amount' },
	{ key: 'date', label: 'Date' }
]

const sample_data = ref([
	{
		id: 123,
		type: 'VAT',
		fees: '7%',
		configured_by: 'Sherif Abubakri',
		time: '2023-10-25',
		status: 'active'
	},
	{
		id: 123,
		type: 'VAT',
		fees: '7%',
		configured_by: 'Sherif Abubakri',
		time: '2023-10-25',
		status: 'active'
	},
])

const currentChargeType = computed(() => {
	if(!allChargeTypes.value.length) return null
	return allChargeTypes.value.find((el) => el.id === config.value.additional_charge_type_id)
})

watch([status, date], () => {
	fetchHistory()
})

fetchAllChargeTypesWithoutLimit()
fetchSingleConfiguredCharges(route.params.configureId)
fetchHistory()
currentPage.value = 1
// date.value = []
</script>

<style scoped>
h1,h2,h3,h4,h5,h6,span, label, p{
	margin: 0;
}
.st_active{
	border: 1px solid #0DAC5C;
	color: #0DAC5C;
	background: #F5FFFA;
}

.st_inactive{
	background-color: #FAFAFA;
	border: 1px solid #6E717C;
	color: #6E717C;
}
</style>